<template>
    <ConsignmentNoteRegistryForm :edit="true"/>
</template>

<script>
import ConsignmentNoteRegistryForm from "@/components/ConsignmentNoteRegistry/ConsignmentNoteRegistryForm";
export default {
    name: 'ConsignmentNotesRegistryEdit',
    components: {ConsignmentNoteRegistryForm},
    computed: {
        currentConsignmentNotesRegistry () {
            return this.$store.state.consignmentNotesRegistries.currentConsignmentNotesRegistry
        },
        breadcrumbs () {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Заказ ПО', link: '/' },
                { title: 'Перечень реестров товарных накладных', link: '/consignment-notes-registries' },
                {
                    title: `Реестр товарных накладных № ${this.currentConsignmentNotesRegistry.number} от ${this.$options.filters.parseDate(this.currentConsignmentNotesRegistry.date)}`,
                    link: `/consignment-notes-registries/${this.$route.params.id}`
                },
                {
                    title: 'Редактирование'
                }
            ]
        }
    }
}
</script>
